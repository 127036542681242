import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { useHasBeenVisible } from '../hooks/useVisibility';
import PageLayout from '../layouts/PageLayout';
import SEO from '../components/seo';
import '../scss/templates/machine.scss';

const MachineHeader = loadable(() => import('../components/Header/MachineHeader'));
const BelowTheFold = loadable(()=> import('../components/BelowTheFold/MachineTemplate'));

const MachineTemplatePage = ({ data }) => {
  const { seo, title, photo, color, specs, CTA } = data.page.frontmatter;
  const { body } = data.page;
  const { machines, blockImage } = data;
  const mainVisible = useRef();
  const hasScrolled = useHasBeenVisible(mainVisible);
  return (
    <PageLayout>
      <SEO title={seo.title} description={seo.metaDescription} ogImage={seo.ogImage.childImageSharp.fixed}  />
      <MachineHeader
        title={title}
        background={photo}
        specs={specs}
        color={color}
      />
      <main ref={mainVisible}>
        {hasScrolled && (
          <BelowTheFold
            body={body}
            blockImage={blockImage}
            CTA={CTA}
            machines={machines}
          />
        )}
      </main>
    </PageLayout>
  );
};

export const MachineTemplatePageQuery = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      fields {
        slug
      }
      body: html
      frontmatter {
        seo {
          title
          metaDescription
          ogImage {
            childImageSharp {
              fixed(width: 1200, height: 630, quality: 70) {
                src
              }
            }
          }
        }
        title
        photo {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        color
        specs {
          name
          value
        }
        CTA {
          title
          description
          photo {
            childImageSharp {
              desktop: fluid(maxWidth: 300, maxHeight: 300, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
              mobile: fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button {
            text
            url
          }
        }
      }
    }
    blockImage: file(relativePath: {eq: "cnc-mill.jpg"}) {
      childImageSharp {
        fluid (maxWidth: 1440, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    machines: allFile(filter: {sourceInstanceName: {eq: "machines"}, childMarkdownRemark: { fields: {slug: {ne: $slug }}}}) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MachineTemplatePage;
